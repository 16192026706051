/* You can add global styles to this file, and also import other style files */

// @import 'material-design-icons/iconfont/material-icons.css';
@import 'material-icons/iconfont/material-icons.scss';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/dist/css/bootstrap.css';

/* global scss variables */
@import './variables';

/* json editor styles */
@import "jsoneditor/dist/jsoneditor.min.css";


.highlight-date {
  background: red;
}

textarea.jsoneditor-text,
.ace-jsoneditor {
  min-height: 600px;
}

textarea.jsoneditor-text {
  width: 600px;
  height: 600px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.table td,
th {
  vertical-align: middle;
}

.mat-elevation-z7 {
  box-shadow: 0 4px 5px -2px #0003, 0 7px 10px 1px #00000024, 0 2px 16px 1px #0000001f;
}
